import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import { RichText } from 'prismic-reactjs'

const Policies = ({ data }) => {
  const cmsData = data.prismic.allPoliciess.edges[0].node
  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data
  const intl = useIntl()

  return (
    <Layout>
      <MetaTags
        title={`${intl.formatMessage({ id: 'policies' })} | Hotel Griffintown`}
      />
      <Header
        eyebrow={cmsData.eyebrow[0].text}
        heading={cmsData.heading[0].text}
        fluid={fluid}
      />
      <Section>
        <Wrapper size='narrow'>
          <SectionBlock>
            <GridRow>
              <GridItem xs='1-1' lg='3-4'>
                {RichText.render(cmsData.body)}
              </GridItem>
            </GridRow>
          </SectionBlock>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PoliciesPage($language: String!) {
    headerImage: file(name: { eq: "img-hero-policies" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allPoliciess(lang: $language) {
        edges {
          node {
            body
            eyebrow
            heading
          }
        }
      }
    }
  }
`

export default Policies
